<template>
  <div class="row p-1" v-for="fl in fieldList" :key="fl">
    <div class="col-3">{{fl}}</div>
    <div class="col-4">min <input type="number" v-model="data['min_'+fl]"></div>
    <div class="col-4">max <input type="number" v-model="data['max_'+fl]"></div>
  </div>
</template>

<script setup>
import {ref, inject, watchEffect} from "vue";

const limits = inject('limits');

const fieldList = inject('limitsField')

const data = ref({})
const setlimits = inject('setlimits')

function setmm()
{
  setlimits(data.value)
}
watchEffect(setmm)

function getlimits()
{
  data.value=limits.value
}
watchEffect(getlimits)
</script>
