<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Главные настройки -->
      <div class="col bg-light shadow border border-primary p-2 m-2">
        <span v-if="state=='edit'">ver: {{data.table_ver}}</span>
        <div class="bd-example" data-example-id="">
          <div class="form-group row">
            <label class="col-2 col-form-label">{{ $t('table.table.name') }}</label>
            <div class="col-10">
              <input class="form-control" type="text" v-model="data.name_title">
              <span v-if="validerr.get('name_title')" class="validerr">{{validerr.get('name_title')}}</span>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.login') }}</label>
          <div class="col-10">
            <input v-if="state=='new'" class="form-control" type="search" v-model="data.user_login">
            <span v-if="state=='edit'">{{ data.user_login }}</span>
            <span v-if="validerr.get('user_login')" class="validerr">{{validerr.get('user_login')}}</span>
          </div>
        </div>

        <div v-if="state=='new'" class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.password') }}</label>
          <div class="col-10">
            <div class="row">
              <div class="col-10">
                <input class="form-control" type="search" v-model="data.user_pass">
                <span v-if="validerr.get('user_pass')" class="validerr">{{validerr.get('user_pass')}}</span>
              </div>
              <div class="col-2">
                <button @click="genPassword()">{{ $t('user.user.generatePassword') }}</button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.game') }}</label>
          <div class="col-10">
            <Select2 v-model="data.game_id" :options="gameList" :settings="{ width: '100%' }" />
            <span v-if="validerr.get('game_id')" class="validerr">{{validerr.get('game_id')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="container-fluid">
            <label>{{ $t('table.table.boxesCount') }}
              <input v-if="state=='new'" type="text" size="1" min="1" max="4" v-model="data.boxes_cnt">
              <span v-if="state=='edit'">{{ data.boxes_cnt }}</span>
              <span v-if="validerr.get('boxes_cnt')" class="validerr">{{validerr.get('boxes_cnt')}}</span>
            </label>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.lang') }}</label>
          <div class="col-10">
            <Select2 v-model="data.lang" :options="lang_list" :settings="{ width: '100%' }" />
            <span v-if="validerr.get('lang')" class="validerr">{{validerr.get('lang')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.tabu_close_shift') }}</label>
          <div class="col-10">
            <input type="checkbox" v-model="data.tabu_close_shift">
            <span v-if="validerr.get('tabu_close_shift')" class="validerr">{{validerr.get('tabu_close_shift')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.promo_counter') }}</label>
          <div class="col-1">
            <input type="checkbox" v-model="data.promo_counter_state">
          </div>
          <div v-if="data.promo_counter_state" class="col-2">
            <el-input type="number" v-model="data.promo_counter" min="1"></el-input>
          </div>
          <div v-if="data.promo_counter_state" class="col-2">
            <span v-if="validerr.get('promo_counter')" class="validerr">{{validerr.get('promo_counter')}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Настройки форматирования -->
      <div class="col bg-light shadow border border-primary rounded-3 p-2 m-2">
        <div class="form-group row">
          <label class="col-3 col-form-label">{{ $t('table.table.aNumberOf') }}</label>
          <div class="col-2">
            <input class="form-control" type="number" v-model="data.decimal_places">
            <span v-if="validerr.get('decimal_places')" class="validerr">{{validerr.get('decimal_places')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-3 col-form-label">{{ $t('table.table.accuracy') }}</label>
          <div class="col-2">
            <input class="form-control" type="search" v-model="data.accuracy">
            <span v-if="validerr.get('accuracy')" class="validerr">{{validerr.get('accuracy')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label><input type="checkbox" v-model="data.masking">{{ $t('table.table.masking') }}</label><br>
          </div>
        </div>
      </div>

      <!-- Настройки звука -->
      <div class="col-4 bg-light shadow border border-primary rounded-3 p-2 m-2">
        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.audioInterface') }}</label>
          <div class="col">
            <input type="range" class="width100p" min="0" max="1" step="0.01" v-model="data.audio_vol_sound">
          </div>
          <div class="col-3">
            <input type="text" class="width100p" v-model="data.audio_vol_sound">
            <span v-if="validerr.get('audio_vol_sound')" class="validerr">{{validerr.get('audio_vol_sound')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.audioJp') }}</label>
          <div class="col">
            <input type="range" class="width100p" min="0" max="1" step="0.01" v-model="data.audio_vol_jackpot">
          </div>
          <div class="col-3">
            <input type="text" class="width100p" v-model="data.audio_vol_jackpot">
            <span v-if="validerr.get('audio_vol_jackpot')" class="validerr">{{validerr.get('audio_vol_jackpot')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.audioVoice') }}</label>
          <div class="col">
            <input type="range" class="width100p" min="0" max="1" step="0.01" v-model="data.audio_vol_voice">
          </div>
          <div class="col-3">
            <input type="text" class="width100p" v-model="data.audio_vol_voice">
            <span v-if="validerr.get('audio_vol_voice')" class="validerr">{{validerr.get('audio_vol_voice')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">{{ $t('table.table.audioAmbiente') }}</label>
          <div class="col">
            <input type="range" class="width100p" min="0" max="1" step="0.01" v-model="data.audio_vol_ambiente">
          </div>
          <div class="col-3">
            <input type="text" class="width100p" v-model="data.audio_vol_ambiente">
            <span v-if="validerr.get('audio_vol_ambiente')" class="validerr">{{validerr.get('audio_vol_ambiente')}}</span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label><input type="checkbox" v-model="data.show_cursor">{{ $t('table.table.showCursor') }}</label><br>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label><input type="checkbox" v-model="data.show_optional_equipment">{{ $t('table.table.showoptionalequipment') }}</label><br>
          </div>
        </div>
      </div>
    </div>

    <!-- Модули -->
    <div class="row">
      <div class="col bg-light shadow border border-primary rounded-3 p-2 m-2">
        <TableChips></TableChips>
      </div>
      <div class="col bg-light shadow border border-primary rounded-3 p-2 m-2">
        <TableLimits></TableLimits>
      </div>
    </div>

    <div class="row">
      <div class="col bg-light shadow border border-primary rounded-3 p-2 m-2">
        <TableBonuses></TableBonuses>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <button @click="save" type="button" class="btn btn-success" :title="$t('game.game.ok')">Ok</button>
      <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('game.game.cancel')">{{ $t('game.game.cancel1') }}</button>
    </div>
    <div class="col-md-2">
      <button v-if="is_super==true && state=='edit'" @click="clear_stat" type="button" class="btn btn-dark" :title="$t('table.table.clear_stat')">{{$t('table.table.clear_stat')}}</button>
    </div>
  </div>
</template>

<script setup>
import TableService from "../../services/table.service";
import BillService from "../../services/bill.service";
import {ref, defineEmits, defineProps, inject, onMounted, computed, watch, provide, reactive} from "vue";
import GameService from "../../services/game.service";
import TableLimits from "./TableLimits.vue";
import TableChips from "./TableChips.vue";
import TableBonuses from "./TableBonuses.vue";

const setState = inject('setState')

const sel_row = inject('sel_row')

const set_result = inject('set_result')

const lang_list = ref(['en', 'es', 'pt', 'ru']);

const is_super = inject('is_super')

const $t = inject('$t')

const date_to_format = inject('date_to_format')

const data = ref({
  'table_status': 'active',
  'name_title': '',
  'user_login': '',
  'user_pass': '',
  'lang': 'es',
  'game_id': '',
  'audio_vol_sound': '1',
  'audio_vol_jackpot': '1',
  'audio_vol_voice': '1',
  'audio_vol_ambiente': '0.4',
  'show_cursor': 'false',
  'show_optional_equipment': 'false',
  'masking': 'false',
  'decimal_places': '',
  'accuracy': '',
  'boxes_cnt': '',
  'tabu_close_shift': 'false',
  'promo_counter_state': false,
  'promo_counter': 0,
  'chips': {
    'label_chip1': '',
    'label_chip2': '',
    'label_chip3': '',
    'label_chip4': '',
    'label_chip5': '',
    'value_chip1': '',
    'value_chip2': '',
    'value_chip3': '',
    'value_chip4': '',
    'value_chip5': ''
  },
  'limits': {
    'min_dozen': '',
    'max_dozen': '',
    'min_split': '',
    'max_split': '',
    'min_column': '',
    'max_column': '',
    'min_corner': '',
    'max_corner': '',
    'min_street': '',
    'max_street': '',
    'min_even_odd': '',
    'max_even_odd': '',
    'min_six_line': '',
    'max_six_line': '',
    'min_red_black': '',
    'max_red_black': '',
    'min_straight_up': '',
    'max_straight_up': '',
    'min_zones_1_18_19_36': '',
    'max_zones_1_18_19_36': ''
  },
  'bonuses': {}
})
provide('data', data)

const chipsField = [
  'chip1',
  'chip2',
  'chip3',
  'chip4',
  'chip5'
]
provide('chipsField', chipsField)

const chips = ref({});
provide('chips', chips)

function setchips(chips)
{
  data.value.chips=chips
}
provide('setchips', setchips)

const limitsField = [
  'dozen',
  'split',
  'column',
  'corner',
  'street',
  'even_odd',
  'six_line',
  'red_black',
  'straight_up',
  'zones_1_18_19_36'
]
provide('limitsField', limitsField)

const limits = ref({});
provide('limits', limits)

function setlimits(limits)
{
  data.value.limits=limits
}
provide('setlimits', setlimits)

const bonuses = ref([]);
provide('bonuses', bonuses)

const gameList = ref()

const selOneBill = inject('selOneBill')
const selOneBillInfo = inject('selOneBillInfo')
const genPass = inject('genPass')
const state = inject('state')

function genPassword()
{
  data.value.user_pass=genPass()
}

const validatas = inject('validatas')
const validerr = ref(new Map())
const verdata = reactive(new Map([
  ['name_title', {reg: '.+'}],
  ['user_login', {reg: '[A-Za-z0-9]'}],
  ['game_id', {reg: '.+'}],
  ['audio_vol_sound', {min: '.+'}],
  ['audio_vol_jackpot', {min: '.+'}],
  ['audio_vol_voice', {min: '.+'}],
  ['audio_vol_ambiente', {min: '.+'}],
  ['decimal_places', {min: '.+'}],
  ['accuracy', {min: '.+'}],
  ['boxes_cnt', {min: '.+'}],
  ['promo_counter', {reg: '^[0-9]*$', min: 1, dépendance: {'promo_counter_state': true}}],
  ['bonuses.golden_chip.contribution_percentage', {reg: '^[0-9.,]*$', min: 0.01, max: 100, dépendance: {'bonuses.golden_chip.state': true}}],
  ['bonuses.golden_chip.threshold', {reg: '^[0-9]*$', min: 1, dépendance: {'bonuses.golden_chip.state': true}}],
  ['bonuses.challenge.end_date', {dépendance: {'bonuses.challenge.state': true}}],
  ['bonuses.challenge.amount', {reg: '^[0-9]*$', min: 1, dépendance: {'bonuses.challenge.state': true}}],
  ['bonuses.free_game.amount', {reg: '^[0-9]*$', min: 1, dépendance: {'bonuses.free_game.state': true}}],
  ['bonuses.deposit.threshold', {reg: '^[0-9]*$', min: 1, dépendance: {'bonuses.deposit.state': true}}],
  ['bonuses.cashback.threshold', {reg: '^[0-9]*[.,]{0,1}[0-9]*$', min: 1, max: 20, dépendance: {'bonuses.cashback.state': true}}],
  ['bonuses.cashback.upper_threshold', {reg: '^[0-9]*[.,]{0,1}[0-9]*$', min: 250, dépendance: {'bonuses.cashback.state': true}}],
  ['bonuses.cashback.max_bet', {reg: '^[0-9]*$', min: 1, dépendance: {'bonuses.cashback.state': true}}],
]));
provide('validerr', validerr)

const deepClone=inject('deepClone')

function closeEdit()
{
  setState('list')
}

function get(sel)
{
  TableService.get(sel).then(
      (response) => {
        data.value = response.data;

        if(data.value.tabu_close_shift=='true') {
          data.value.tabu_close_shift=true
        } else {
          data.value.tabu_close_shift=false
        }

        if(data.value.promo_counter_state=='true') {
          data.value.promo_counter_state=true
        } else {
          data.value.promo_counter_state=false
        }

        limits.value = data.value.limits
        chips.value = data.value.chips
        bonuses.value = data.value.bonuses

        if(bonuses.value.challenge.end_date==null) {
          let def_dt=new Date()
          def_dt.setHours(0)
          def_dt.setMinutes(0)
          def_dt.setSeconds(0)
          def_dt.setMilliseconds(0)
          def_dt.setDate(
              def_dt.getDate() + 14
          );

          bonuses.value.challenge.end_date=def_dt
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function save()
{
  //переделать на функцию
  if(data.value.bonuses.golden_chip.contribution_percentage!=null && data.value.bonuses.golden_chip.contribution_percentage!='') {
    data.value.bonuses.golden_chip.contribution_percentage = data.value.bonuses.golden_chip.contribution_percentage.toString().replace(',', '.')
  } else {
    data.value.bonuses.golden_chip.contribution_percentage=null
  }

  if(data.value.bonuses.golden_chip.threshold!=null && data.value.bonuses.golden_chip.threshold!='') {
    data.value.bonuses.golden_chip.threshold=data.value.bonuses.golden_chip.threshold.toString().replace('.', ',')
  } else {
    data.value.bonuses.golden_chip.threshold=null
  }

  let data_str
  let datav = deepClone(data.value);
  if(data.value.bonuses.cashback.threshold!==undefined && data.value.bonuses.cashback.threshold!==null) {
    data_str=data.value.bonuses.cashback.threshold.toString().replace(',', '.')
    data.value.bonuses.cashback.threshold=data_str
    datav.bonuses.cashback.threshold=parseFloat(data_str)*selOneBillInfo.value.usd_rate
  }

  if(data.value.bonuses.cashback.upper_threshold!==undefined && data.value.bonuses.cashback.upper_threshold!==null) {
    data_str=data.value.bonuses.cashback.upper_threshold.toString().replace(',', '.')
    data.value.bonuses.cashback.upper_threshold=data_str
    datav.bonuses.cashback.upper_threshold=parseFloat(data_str)*selOneBillInfo.value.usd_rate
  }

  validerr.value = validatas(datav, verdata)

  if(validerr.value.size>0) {
    return false
  }

  if(data.value.table_status==true) {
    data.value.table_status='active'
  } else {
    data.value.table_status='not_active'
  }

  if(data.value.tabu_close_shift==true) {
    data.value.tabu_close_shift='true'
  } else {
    data.value.tabu_close_shift='false'
  }

  if(data.value.bonuses.challenge.indefinitely==true) {
    data.value.bonuses.challenge.indefinitely='true'
  } else {
    data.value.bonuses.challenge.indefinitely='false'
  }

  data.value.bonuses.challenge.end_date=date_to_format(new Date(data.value.bonuses.challenge.end_date))

  TableService.save(selOneBill.value, data.value.game_id, data.value, data.value.id).then(
      () => {
        setState('list')
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getGames(selBill)
{
  GameService.getGamesListByBill(selBill).then(
    (response) => {
      gameList.value=response.data
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function getBillData()
{
  BillService.getBill(selOneBill.value).then(
      (response) => {
        for(let cr in chipsField) {
          let cur = chipsField[cr]
          let num=cur.replace('chip', '')

          chips.value['label_chip'+num]=parseFloat(response.data['but'+num])
          chips.value['value_chip'+num]=parseFloat(response.data['but'+num])
        }

        for(let cr in limitsField) {
          let cur = limitsField[cr]

          limits.value['min_'+cur]=parseFloat(response.data.bet_min)
          limits.value['max_'+cur]=parseFloat(response.data.bet_max)
        }

        bonuses.value = response.data.bonuses

        if(bonuses.value.challenge.end_date==null) {
          let def_dt=new Date()
          def_dt.setHours(0)
          def_dt.setMinutes(0)
          def_dt.setSeconds(0)
          def_dt.setMilliseconds(0)
          def_dt.setDate(
              def_dt.getDate() + 14
          );

          bonuses.value.challenge.end_date=def_dt

          data.value.bonuses=bonuses.value
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function clear_stat()
{
  if (confirm($t('table.table.clear_stat')+"?")) {
    TableService.clear_stat(data.value.id).then(
        () => {
          setState('list')
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}

onMounted(() => {
  getGames(selOneBill.value)
  if(sel_row.value!=='') {
    get(sel_row.value)
  }

  if(sel_row.value=='') {
    getBillData()
  }
})
</script>
