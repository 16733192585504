<template>
  <div v-if="cntSelBill!=1" class="container-fluid">{{ $t('user.users.chooseOneAccount') }}</div>
  <p></p>
  <div v-if="cntSelBill==1" class="container-fluid">
    <button v-if="state=='list'" @click="setState('new')" class="btn-success">{{ $t('table.tables.add') }}</button>
    <p></p>
    <vue-good-table v-if="state=='list'"
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <TableActions v-if="props.column.field == 'actions'" :selRow="props.row.id"></TableActions>
      </template>
    </vue-good-table>

    <Table v-if="state!='list'"></Table>
  </div>
</template>

<script setup>
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import TableService from "../../services/table.service";
import TableActions from "./TableActions.vue";
import Table from "./Table.vue";
import {ref, inject, computed, onMounted, provide, watch, watchEffect} from "vue";

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const set_result = inject('set_result')

const sel_row = ref('')
provide('sel_row', sel_row)

const selOneBill = inject('selOneBill')
const cntSelBill = inject('cntSelBill')

const state = ref('list')
provide('state', state)

function setState(st, id='')
{
  state.value=st
  sel_row.value=id

  if(st=='list') {
    all(selOneBill.value)
  }
}
provide('setState', setState)

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
      {
        label: $t('table.tables.id'),
        field: 'id',
        filterOptions: {enabled: true}
      },
      {
        label: $t('table.tables.name_title'),
        field: 'name_title',
        filterOptions: {enabled: true}
      },
      {
        label: $t('table.tables.ver'),
        field: 'ver',
        filterOptions: {enabled: true}
      },
      {
        label: $t('table.tables.game'),
        field: 'fid_game',
        filterOptions: {enabled: true}
      },
      {
        label: $t('table.tables.boxes_cnt'),
        field: 'boxes_cnt',
        filterOptions: {enabled: true}
      },
      {
        label: $t('table.tables.balance'),
        field: 'balance'
      },
      {
        label: $t('app.public.action'),
        field: 'actions',
        html: true,
        width: '100px',
      },
    ])
const rows = ref([])

function all(bill_id)
{
  TableService.all(bill_id).then(
    (response) => {
      rows.value = response.data;
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function changeSelBill()
{
  if(cntSelBill.value==1) {
    all(selOneBill.value)
  }
}
watchEffect(changeSelBill)

onMounted(() => {
  if(cntSelBill.value==1) {
    all(selOneBill.value)
  }
})
</script>
