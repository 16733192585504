<template>
  <div class="row p-1" v-for="fl in fieldList" :key="fl">
    <div class="col-2">{{ $t('table.table.'+fl) }}</div>
    <div class="col-4">label <input type="text" v-model="data['label_'+fl]"></div>
    <div class="col-4">value <input type="number" v-model="data['value_'+fl]"></div>
  </div>
</template>

<script setup>
import {ref, inject, watchEffect} from "vue";

const chips = inject('chips');

const fieldList = inject('chipsField')

const data = ref({})
const setchips = inject('setchips')

function setmm()
{
  setchips(data.value)
}
watchEffect(setmm)

function getchips()
{
  data.value=chips.value
}
watchEffect(getchips)
</script>
