import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'table/';

class TableService {
  save(bill_id, game_id, data, table_id) {
    return axios.post(API_URLA + 'save/', {"bill_id": bill_id, "game_id": game_id, "data": data, "table_id": table_id});
  }
  get(table_id) {
    return axios.post(API_URLA + 'get/', {"table_id": table_id});
  }
  all(bill_id) {
    return axios.post(API_URLA + 'all/', {"bill_id": bill_id});
  }
  clear_stat(table_id) {
    return axios.post(API_URLA + 'clear_stat/', {"table_id": table_id});
  }
}

export default new TableService();
