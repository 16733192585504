<template>{{ $t('bonuses.bonuses') }}
  <div class="row p-2 m-2 border" v-for="dt in bonuses" :key="dt">
    <div class="col-3">
      <label class="p-2">
        <input type="checkbox" name="bonuses" v-model="dt.state" :id="dt.code" :value="dt.code"> {{ $t('bonuses.'+dt.code) }}
      </label>
    </div>
    <div class="col">
      <div v-if="dt.code=='golden_chip' && dt.state==true" class="row">
        <div class="col">
          {{ $t('bonuses.contribution_percentage') }}
          <el-input v-model="dt.contribution_percentage" @input="clearValid('bonuses.golden_chip.contribution_percentage')"></el-input>
          <span v-if="validerr.get('bonuses.golden_chip.contribution_percentage')" class="validerr">{{validerr.get('bonuses.golden_chip.contribution_percentage')}}</span>
        </div>
        <div class="col">
          {{ $t('bonuses.threshold') }}
          <el-input v-model="dt.threshold" @input="clearValid('bonuses.golden_chip.threshold')"></el-input>
          <span v-if="validerr.get('bonuses.golden_chip.threshold')" class="validerr">{{validerr.get('bonuses.golden_chip.threshold')}}</span>
        </div>
      </div>

      <div v-if="dt.code=='challenge' && dt.state==true" class="row">
        <div class="col">
          {{ $t('bonuses.end_date') }}
          <el-config-provider :locale="langEl">
            <el-date-picker
                v-model="dt.end_date"
                type="datetime"
                :format="dataformatEl"
            />
          </el-config-provider><br>
          <span v-if="validerr.get('bonuses.challenge.end_date')" class="validerr">{{validerr.get('bonuses.challenge.end_date')}}</span>
        </div>
        <div class="col">
          <label>{{ $t('bonuses.indefinitely') }} <input type="checkbox" name="bonuses" v-model="dt.indefinitely"></label>
          <span v-if="validerr.get('bonuses.challenge.threshold')" class="validerr">{{validerr.get('bonuses.challenge.threshold')}}</span>
        </div>
        <div class="col">
          {{ $t('bonuses.amount') }}
          <el-input v-model="dt.amount" @input="clearValid('bonuses.challenge.amount')"></el-input>
          <span v-if="validerr.get('bonuses.challenge.amount')" class="validerr">{{validerr.get('bonuses.challenge.amount')}}</span>
        </div>
      </div>

      <div v-if="dt.code=='free_game' && dt.state==true" class="row">
        <div class="col-3">
          {{ $t('bonuses.amount') }}
          <el-input v-model="dt.amount" @input="clearValid('bonuses.free_game.amount')"></el-input>
          <span v-if="validerr.get('bonuses.free_game.amount')" class="validerr">{{validerr.get('bonuses.free_game.amount')}}</span>
        </div>
      </div>

      <div v-if="dt.code=='deposit' && dt.state==true" class="row">
        <div class="col-3">
          {{ $t('bonuses.trigger limit') }}
          <el-input v-model="dt.threshold" @input="clearValid('bonuses.deposit.threshold')"></el-input>
          <span v-if="validerr.get('bonuses.deposit.threshold')" class="validerr">{{validerr.get('bonuses.deposit.threshold')}}</span>
        </div>
      </div>

      <div v-if="dt.code=='cashback' && dt.state==true" class="row">
        <div class="col-3">
          {{ $t('bonuses.down_threshold') }}
          <el-input v-model="dt.threshold" @input="clearValid('bonuses.cashback.threshold')"></el-input>
          <span v-if="validerr.get('bonuses.cashback.threshold')" class="validerr">{{validerr.get('bonuses.cashback.threshold')}}</span>
          <span v-if="down_threshold_cur">{{down_threshold_cur}} usd</span>
        </div>
        <div class="col-3">
          {{ $t('bonuses.upper_threshold') }}
          <el-input v-model="dt.upper_threshold" @input="clearValid('bonuses.cashback.upper_threshold')"></el-input>
          <span v-if="validerr.get('bonuses.cashback.upper_threshold')" class="validerr">{{validerr.get('bonuses.cashback.upper_threshold')}}</span>
          <span v-if="upper_threshold_cur">{{upper_threshold_cur}} usd</span>
        </div>
        <div class="col-3">
          {{ $t('bonuses.trigger limit') }}
          <el-input v-model="dt.max_bet" @input="clearValid('bonuses.cashback.max_bet')"></el-input>
          <span v-if="validerr.get('bonuses.cashback.max_bet')" class="validerr">{{validerr.get('bonuses.cashback.max_bet')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, ref} from "vue";

const bonuses = inject('bonuses');
const validerr = inject('validerr')

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const selOneBillInfo=inject('selOneBillInfo')

function clearValid(dt)
{
  validerr.value.delete(dt)
}

function down_threshold_cur_fn()
{
  let res=null

  if(bonuses.value.cashback.threshold!==undefined && bonuses.value.cashback.threshold!==null) {
    res=parseFloat(bonuses.value.cashback.threshold.toString().replace(',', '.'))*selOneBillInfo.value.usd_rate
  }

  return res
}
const down_threshold_cur=computed(down_threshold_cur_fn)

function upper_threshold_cur_fn()
{
  let res=null

  if(bonuses.value.cashback.upper_threshold!==undefined && bonuses.value.cashback.upper_threshold!==null) {
    res=parseFloat(bonuses.value.cashback.upper_threshold.toString().replace(',', '.'))*selOneBillInfo.value.usd_rate
  }
  return res
}
const upper_threshold_cur=computed(upper_threshold_cur_fn)
</script>
